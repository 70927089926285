import { LabelColor } from 'components/Label/Label'
import { InvoiceStatus } from 'interfaces/invoice'

export const statusLabel: Record<InvoiceStatus, LabelColor> = {
  [InvoiceStatus.Open]: 'blue',
  [InvoiceStatus.Paid]: 'green',
  [InvoiceStatus.Declined]: 'red',
  [InvoiceStatus.Overdue]: 'red',
}

export const statusLabelText = {
  [InvoiceStatus.Open]: 'Open',
  [InvoiceStatus.Paid]: 'Paid',
  [InvoiceStatus.Declined]: 'Failed',
  [InvoiceStatus.Overdue]: 'Overdue',
}
