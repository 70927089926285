import { useCallback, useState } from 'react'

export const useToggle = (initialState = false): [boolean, () => void, () => void, () => void] => {
  const [isOpen, setIsOpen] = useState(initialState)

  const handleOpen = useCallback(() => setIsOpen(true), [])
  const handleClose = useCallback(() => setIsOpen(false), [])
  const handleToggle = useCallback(() => setIsOpen(isOpen => !isOpen), [])

  return [isOpen, handleOpen, handleClose, handleToggle]
}
