import { InvoiceStatus } from 'interfaces/invoice'

export const DEFAULT_TRANSACTION_ID = -1
export const REMOVE_TRANSACTION_ID_FROM_LOCALSTORAGE_DURATION = 60 * 1000 * 5 // 5min

export enum PaymentVariant {
  Bank = 'bank',
  Card = 'card',
}

export enum InvoiceStep {
  Open = 'open',
  Paid = 'paid',
  Failed = 'failed',
}

export const newStep = {
  [InvoiceStatus.Open]: InvoiceStep.Open,
  [InvoiceStatus.Overdue]: InvoiceStep.Open,
  [InvoiceStatus.Paid]: InvoiceStep.Paid,
  [InvoiceStatus.Declined]: InvoiceStep.Failed,
}
