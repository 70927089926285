import { ReactComponent as NotFoundImage } from 'assets/images/404.svg'
import PaymentCard from 'components/PaymentCard/PaymentCard'

import styles from './NotFound.module.scss'

const NotFound = () => (
  <div className={styles.component}>
    <PaymentCard>
      <div className={styles.content}>
        <NotFoundImage className={styles.logo} />
        <div className={styles.title}>Invoice not found</div>
        <div className={styles.text}>
          Unfortunately the invoice wasn’t found. If you have any questions, please contact our support center.
        </div>
      </div>
    </PaymentCard>
  </div>
)

export default NotFound
