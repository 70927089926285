import { FC } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import NotFound from 'pages/404/NotFound'
import Invoice from 'pages/invoice/Invoice'

import { routes } from './routes'

const Router: FC = () => (
  <Routes>
    <Route path={routes.pay} element={<Invoice />} />
    <Route path={routes.notFound} element={<NotFound />} />
    <Route path="*" element={<Navigate to={routes.notFound} />} />
  </Routes>
)

export default Router
