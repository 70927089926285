import { AcceptHostedTransactionResponse } from 'react-acceptjs'

import { InvoiceDTO, InvoicePaymentPayload } from 'interfaces/invoice'
import useApiRequest from 'utils/api/useApiRequest'

import { fetchInvoiceDetails, makeInvoicePayment } from './requests'

export const useFetchInvoiceDetails = () => useApiRequest<[string], InvoiceDTO>(fetchInvoiceDetails)

export const useInvoicePayment = () =>
  useApiRequest<[InvoicePaymentPayload], AcceptHostedTransactionResponse>(makeInvoicePayment)
