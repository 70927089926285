import { FC } from 'react'
import { BrowserRouter } from 'react-router-dom'

import Layout from 'components/Layout/Layout'

import Router from './Router'

export const App: FC = () => (
  <Layout>
    <BrowserRouter>
      <Router />
    </BrowserRouter>
  </Layout>
)
