import { useCallback, useState } from 'react'
import { AxiosResponse } from 'axios'

const useApiRequest = <Args extends any[], R = unknown>(
  method: (...args: Args) => Promise<AxiosResponse<R>>,
): [(...args: Args) => Promise<R>, R | null, boolean, null] => {
  const [data, setData] = useState<R | null>(null)
  const [error, setError] = useState<any | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  const handleRequest = useCallback(
    (...args: Args) => {
      setIsLoading(true)

      return method(...args)
        .then(res => {
          const data = res?.data

          setData(res.data)

          return Promise.resolve(data)
        })
        .catch(error => {
          setError(error)

          return Promise.reject(error)
        })
        .finally(() => setIsLoading(false))
    },
    [method],
  )

  return [handleRequest, data, isLoading, error]
}

export default useApiRequest
