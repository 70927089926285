import { FC, memo } from 'react'
import cn from 'classnames'

import styles from './Label.module.scss'

export type LabelColor = 'green' | 'red' | 'blue'

interface LabelProps {
  title: string
  bgColor: LabelColor
  className?: string
  onClick?: () => void
}

const Label: FC<LabelProps> = ({ title, className, bgColor, onClick }) => {
  const classNames = cn(styles.component, className, {
    [styles.red]: bgColor === 'red',
    [styles.green]: bgColor === 'green',
    [styles.blue]: bgColor === 'blue',
  })

  return (
    <span className={classNames} onClick={onClick}>
      {title}
    </span>
  )
}

export default memo(Label)
