export interface InvoiceDTO {
  status: InvoiceStatus
  invoice: InvoiceInfo
  amount: InvoiceAmount
  billing_details: InvoiceBillingDetails
  payment_date?: Date
  pe: InvoicePe
}

export interface InvoicePaymentPayload {
  invoice_id: string
  descriptor: string
  value: string
  entity: InvoicePe
  payment_type: PaymentType
}

export type InvoicePe = 'e7ce4310df031' | '3c6d033480a70' | '7cd993026c7be'

export type PaymentType = 0 | 1

export enum InvoiceStatus {
  Open = 0,
  Paid = 1,
  Declined = 2,
  Overdue = 3,
}

export interface InvoiceAmount {
  card: number
  paid_amount?: number
  bank_account: number
  fee_percent: number
  currency: string
}

export interface InvoiceInfo {
  id: string
  due_date: string
  description: string[]
}

export interface InvoiceBillingDetails {
  from: {
    name: string
  }
  to: {
    name: string
  }
}
