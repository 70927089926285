import { AuthData } from 'react-acceptjs'

import { PaymentVariant } from 'pages/invoice/constants'
import { InvoicePe, PaymentType } from 'interfaces/invoice'

export const PAYMENT_VARIANT_LOCALSTORAGE_KEY = 'paymentVariant'

export const authData: Record<InvoicePe, AuthData> = {
  ['e7ce4310df031']: {
    apiLoginID: process.env.REACT_APP_AUTHORIZE_NET_PFM_ID || '',
    clientKey: process.env.REACT_APP_AUTHORIZE_NET_PFM_KEY || '',
  },
  ['3c6d033480a70']: {
    apiLoginID: process.env.REACT_APP_AUTHORIZE_NET_PFM_HOSTING_ID || '',
    clientKey: process.env.REACT_APP_AUTHORIZE_NET_PFM_HOSTING_KEY || '',
  },
  ['7cd993026c7be']: {
    apiLoginID: process.env.REACT_APP_AUTHORIZE_NET_SEAGIL_ID || '',
    clientKey: process.env.REACT_APP_AUTHORIZE_NET_SEAGIL_KEY || '',
  },
}

export const paymentType: Record<PaymentVariant, PaymentType> = {
  [PaymentVariant.Card]: 0,
  [PaymentVariant.Bank]: 1,
}
