import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'

import { CancelableRequest } from 'interfaces/api'

class ApiClient {
  client: AxiosInstance

  constructor() {
    const client = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}/`,
      responseType: 'json',
    })

    this.client = client
  }

  getCancelableAttributes() {
    const source = axios.CancelToken.source()

    return {
      cancel: () => {
        source.cancel()
      },
    }
  }

  public get<T, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig) {
    const { cancel } = this.getCancelableAttributes()

    const request = this.client.get(url, {
      ...config,
    }) as CancelableRequest<R>

    request.cancel = cancel

    return request
  }

  public post<T, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig) {
    const { cancel } = this.getCancelableAttributes()

    const request = this.client.post(url, data, {
      ...config,
    }) as CancelableRequest<R>

    request.cancel = cancel

    return request
  }

  public put<T, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig) {
    const { cancel } = this.getCancelableAttributes()

    const request = this.client.put(url, data, {
      ...config,
    }) as CancelableRequest<R>

    request.cancel = cancel

    return request
  }

  public patch<T, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig) {
    const { cancel } = this.getCancelableAttributes()

    const request = this.client.patch(url, data, {
      ...config,
    }) as CancelableRequest<R>

    request.cancel = cancel

    return request
  }

  public delete<T, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig) {
    const { cancel } = this.getCancelableAttributes()

    const request = this.client.delete(url, {
      ...config,
    }) as CancelableRequest<R>

    request.cancel = cancel

    return request
  }
}

export default new ApiClient()
