import { FC } from 'react'
import cn from 'classnames'

import styles from './Spinner.module.scss'

interface SpinnerProps {
  className?: string
}

const Spinner: FC<SpinnerProps> = ({ className }) => (
  <div className={cn(styles.component, className)}>
    <div className={styles.spinner} />
  </div>
)

export default Spinner
