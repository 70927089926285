import { FC } from 'react'

import icons, { IconsMapType } from 'assets/icons/icons'

interface IconProps {
  name: keyof IconsMapType
}

const Icon: FC<IconProps> = ({ name }) => {
  const IconFile = icons[name]

  if (!IconFile) return null

  return <IconFile />
}

export default Icon
