import { FC } from 'react'

import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg'
import Button from 'components/Button/Button'

import styles from './FailedSection.module.scss'

const FailedSection: FC = () => {
  const handleClick = () => window.location.reload()

  return (
    <div className={styles.component}>
      <div className={styles.support}>
        <WarningIcon className={styles.icon} />
        <div>
          Something went wrong. Please try again or contact your bank or{' '}
          <a href="https://portside.co/support/" className={styles.link}>
            Portside support.
          </a>
        </div>
      </div>

      <Button className={styles.button} variant="primary" title="Try Again" onClick={handleClick} />
    </div>
  )
}

export default FailedSection
