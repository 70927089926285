import background from 'assets/images/mainBg.svg'
import Footer from 'components/Footer/Footer'
import { FCWithChildren } from 'interfaces/common'

import styles from './Layout.module.scss'

const Layout: FCWithChildren = ({ children }) => (
  <div className={styles.component}>
    <div className={styles.background}>
      <img src={background} />
    </div>
    <div className={styles.content}>{children}</div>
    <Footer />
  </div>
)

export default Layout
