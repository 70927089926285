import { FC, ReactNode } from 'react'
import cn from 'classnames'

import styles from './InfoWrapper.module.scss'

interface InfoWrapperProps {
  title: string
  label: ReactNode
  className?: string
}

const InfoWrapper: FC<InfoWrapperProps> = ({ title, label, className }) => (
  <div className={cn(styles.component, className)}>
    <div className={styles.title}>{title}</div>
    <div>{label}</div>
  </div>
)

export default InfoWrapper
