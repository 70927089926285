import cn from 'classnames'

import { ReactComponent as Logo } from 'assets/images/logo.svg'
import { FCWithChildren } from 'interfaces/common'

import styles from './PaymentCard.module.scss'

interface PaymentCardPorps {
  className?: string
  contentClassName?: string
}

const PaymentCard: FCWithChildren<PaymentCardPorps> = ({ className, contentClassName, children }) => (
  <div className={cn(styles.component, className)}>
    <div className={cn(styles.content, contentClassName)}>
      <Logo className={styles.logo} />
      {children}
    </div>

    <div className={styles.contacts}>
      Questions? Email
      <a href="mailto:ar@portside.co" className={styles.link}>
        ar@portside.co
      </a>
      and reference your invoice #.
    </div>
  </div>
)

export default PaymentCard
