import { FC } from 'react'

import styles from './Footer.module.scss'

const Footer: FC = () => (
  <div className={styles.component}>
    <div className={styles.rights}>{`© 2017-${new Date().getFullYear()} Portside, Inc. All rights reserved.`}</div>
  </div>
)

export default Footer
