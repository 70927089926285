import { FC, memo } from 'react'
import cn from 'classnames'

import { IconsMapType } from 'assets/icons/icons'
import Icon from 'components/Icon/Icon'

import styles from './RadioButton.module.scss'

interface RadioButtonProps {
  label?: string
  icon?: keyof IconsMapType
  className?: string
  isChecked: boolean
  name: string
  onChange: (name: string) => void
}

const RadioButton: FC<RadioButtonProps> = ({ label, icon, className, name, isChecked, onChange }) => {
  const handleChange = () => onChange(name)

  return (
    <label className={cn(styles.component, className)}>
      <input className={styles.radioButton} type="radio" checked={isChecked} onChange={handleChange} />
      {icon && <Icon name={icon} />}
      <span>{label}</span>
    </label>
  )
}

export default memo(RadioButton)
