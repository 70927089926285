import { FC, useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import PaymentCard from 'components/PaymentCard/PaymentCard'
import Spinner from 'components/Spinner/Spinner'
import { routes } from 'app/routes'
import { InvoiceStatus, PaymentType } from 'interfaces/invoice'
import { useToggle } from 'hooks/useToggle'
import { useInvoicePayment } from 'api/invoice/hooks'
import { useFetchInvoiceDetails } from 'api/invoice/hooks'
import { formatDate } from 'utils/formats/formatDate'
import { parseNumber } from 'utils/formats/numbers'

import FailedSection from './components/FailedSection/FailedSection'
import InfoWrapper from './components/InfoWrapper/InfoWrapper'
import InvoiceInfo from './components/InvoiceInfo/InvoiceInfo'
import PaymentForm from './components/PaymentForm/PaymentForm'
import { InvoiceStep, newStep } from './constants'

import styles from './Invoice.module.scss'

const Invoice: FC = () => {
  const [step, setStep] = useState<InvoiceStep>()

  const { pathname, search } = useLocation()
  const navigate = useNavigate()

  const [fetchDetails, invoiceDetails, isLoading] = useFetchInvoiceDetails()
  const [makePayment] = useInvoicePayment()
  const [isPaymentProcessing, setPaymentProcessingStarted, setPaymentProcessingCompleted] = useToggle()

  const query = `${pathname}${search}`
  const entity = invoiceDetails?.pe

  const handleError = useCallback(() => {
    setStep(InvoiceStep.Failed)
    setPaymentProcessingCompleted()
  }, [])

  const handleCheckStatus = useCallback(() => {
    fetchDetails(query).catch(() => {
      handleError()
    })
  }, [handleError])

  const handlePayment = useCallback(
    (descriptor: string, value: string, paymentType: PaymentType) => {
      if (!invoiceDetails || !entity) return

      setPaymentProcessingStarted()

      makePayment({
        entity,
        invoice_id: invoiceDetails.invoice.id,
        payment_type: paymentType,
        descriptor,
        value,
      })
        .then(result => {
          if (result.messages.resultCode === 'Error') {
            setStep(InvoiceStep.Failed)
            setPaymentProcessingCompleted()

            return
          }

          handleCheckStatus()
        })
        .catch(handleError)
    },
    [entity, invoiceDetails],
  )

  useEffect(() => {
    fetchDetails(query).catch(() => navigate(routes.notFound))
  }, [query, fetchDetails, navigate])

  useEffect(() => {
    const status = invoiceDetails?.status

    if (!status?.toString()) return

    setStep(newStep[status])

    if ([InvoiceStatus.Paid, InvoiceStatus.Declined].includes(status)) {
      setPaymentProcessingCompleted()
    }
  }, [invoiceDetails?.status, setPaymentProcessingCompleted])

  const amount = `$${parseNumber(invoiceDetails?.amount?.bank_account || invoiceDetails?.amount?.paid_amount)}`
  const loading = isLoading || isPaymentProcessing

  return (
    <div className={styles.component}>
      <PaymentCard contentClassName={styles.paymentCardContent}>
        {loading && <Spinner className={styles.spinner} />}

        {invoiceDetails && (
          <InvoiceInfo
            dueDate={invoiceDetails.invoice.due_date}
            amount={amount}
            status={invoiceDetails.status}
            to={invoiceDetails.billing_details?.to?.name}
            from={invoiceDetails.billing_details?.from?.name}
            invoiceNumber={invoiceDetails.invoice.id}
            description={invoiceDetails.invoice.description}
          />
        )}
        <div className={styles.content}>
          {step === InvoiceStep.Open && invoiceDetails && entity && (
            <PaymentForm
              feePercent={invoiceDetails?.amount.fee_percent}
              cardAmount={invoiceDetails?.amount.card}
              isLoading={loading}
              entity={entity}
              onPayment={handlePayment}
            />
          )}

          {step === InvoiceStep.Paid && (
            <InfoWrapper
              title="Paid"
              label={invoiceDetails?.payment_date ? formatDate(new Date(invoiceDetails.payment_date)) : ''}
            />
          )}

          {step === InvoiceStep.Failed && <FailedSection />}
        </div>
      </PaymentCard>
    </div>
  )
}

export default Invoice
