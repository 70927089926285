import { FC } from 'react'
import cn from 'classnames'

import Label from 'components/Label/Label'
import { InvoiceStatus } from 'interfaces/invoice'
import { formatDate } from 'utils/formats/formatDate'

import InfoWrapper from '../InfoWrapper/InfoWrapper'

import { statusLabel, statusLabelText } from './constants'

import styles from './InvoiceInfo.module.scss'

interface InvoiceInfoProps {
  dueDate: string
  amount: string
  status: InvoiceStatus
  to: string
  from: string
  invoiceNumber: string
  description: string[]
}

const InvoiceInfo: FC<InvoiceInfoProps> = ({ dueDate, amount, status, to, from, invoiceNumber, description }) => (
  <div className={styles.component}>
    <div className={styles.header}>
      <div className={styles.amount}>
        <div>{amount}</div>
        <div className={cn(styles.dueDate, { [styles.overdue]: status === InvoiceStatus.Overdue })}>
          Due {formatDate(new Date(dueDate))}
        </div>
      </div>
      <Label title={statusLabelText[status]} bgColor={statusLabel[status]} />
    </div>

    <div className={styles.content}>
      <InfoWrapper title="To" label={to} />
      <InfoWrapper title="From" label={from} />
      <InfoWrapper title="Invoice" label={invoiceNumber} />
      <InfoWrapper
        title="Memo"
        label={description?.map(memo => (
          <div className={styles.description} key={memo}>
            {memo}
          </div>
        ))}
      />
    </div>
  </div>
)

export default InvoiceInfo
