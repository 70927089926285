import { ReactComponentElement } from 'react'

import { ReactComponent as bankIcon } from './bank.svg'
import { ReactComponent as cardIcon } from './card.svg'
import { ReactComponent as warningIcon } from './warning.svg'

const icons = {
  bankIcon,
  cardIcon,
  warningIcon,
}

export type IconsMapType = {
  [key in keyof typeof icons]: ReactComponentElement<any>
}

export default icons
