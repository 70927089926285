import { FC, useCallback, useEffect, useState } from 'react'
import { HostedForm, HostedFormDispatchDataResponse } from 'react-acceptjs'
import cn from 'classnames'

import { PaymentVariant } from 'pages/invoice/constants'
import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg'
import RadioButton from 'components/RadioButton/RadioButton'
import { InvoicePe, PaymentType } from 'interfaces/invoice'
import { parseNumber } from 'utils/formats/numbers'

import { authData, PAYMENT_VARIANT_LOCALSTORAGE_KEY, paymentType } from './constants'

import styles from './PaymentForm.module.scss'
interface PaymentFormProps {
  cardAmount: number
  feePercent: number
  isLoading: boolean
  entity: InvoicePe
  onPayment: (descriptor: string, value: string, paymentType: PaymentType) => void
}

const PaymentForm: FC<PaymentFormProps> = ({ cardAmount, entity, feePercent, isLoading, onPayment }) => {
  const variant = localStorage.getItem(PAYMENT_VARIANT_LOCALSTORAGE_KEY) as PaymentVariant

  const [paymentVariant] = useState<PaymentVariant>(variant || PaymentVariant?.Bank)

  const handleSubmit = useCallback((response: HostedFormDispatchDataResponse) => {
    if (!response?.opaqueData?.dataDescriptor || !response?.opaqueData?.dataValue) return

    const { dataDescriptor, dataValue } = response.opaqueData

    onPayment(dataDescriptor, dataValue, paymentType[paymentVariant])
  }, [])

  useEffect(() => {
    // clear localStorage after reload
    localStorage.removeItem(PAYMENT_VARIANT_LOCALSTORAGE_KEY)
  }, [])

  const handleReload = useCallback((name: string) => {
    // if we want to change paymentOptions inside HostedForm we need to reload page.
    // just rerender the component will not be working
    // so we set changed paymentVariant to localStorage and after reload get it
    localStorage.setItem(PAYMENT_VARIANT_LOCALSTORAGE_KEY, name)
    window.location.reload()
  }, [])

  return (
    <div className={cn(styles.component, { [styles.loading]: isLoading })}>
      <RadioButton
        icon="bankIcon"
        label="Bank Transfer"
        name={PaymentVariant.Bank}
        onChange={handleReload}
        isChecked={paymentVariant === PaymentVariant.Bank}
      />
      <RadioButton
        icon="cardIcon"
        label="Credit Card"
        className={styles.cardRadioButton}
        name={PaymentVariant.Card}
        onChange={handleReload}
        isChecked={paymentVariant === PaymentVariant.Card}
      />

      {paymentVariant === PaymentVariant.Card && cardAmount && (
        <div className={styles.feeDescription}>
          <WarningIcon className={styles.icon} />
          <div>{`A ${feePercent}% convenience fee will be added for credit card payments. You total will be $${parseNumber(
            cardAmount,
          )}.`}</div>
        </div>
      )}

      <HostedForm
        authData={authData[entity]}
        buttonText="Continue"
        buttonClassName={styles.paymentButton}
        onSubmit={handleSubmit}
        paymentOptions={{
          showCreditCard: paymentVariant === PaymentVariant.Card,
          showBankAccount: paymentVariant === PaymentVariant.Bank,
        }}
      />

      <div className={styles.footer}>
        By submitting this payment, you authorize Portside, Inc. to charge your provided payment method for the total
        amount specified above.
      </div>
    </div>
  )
}

export default PaymentForm
