import { FC, memo, MouseEvent } from 'react'
import cn from 'classnames'

import { IconsMapType } from 'assets/icons/icons'
import Icon from 'components/Icon/Icon'

import styles from './Button.module.scss'

export type ButtonVariants = 'default' | 'primary' | 'secondary'

interface ButtonProps {
  title: string
  icon?: keyof IconsMapType
  variant?: ButtonVariants
  className?: string
  name?: string
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void
}

const Button: FC<ButtonProps> = ({ title, icon, name, variant = 'default', className, onClick }) => {
  const buttonClassName = cn(
    styles.component,
    {
      [styles.default]: variant === 'default',
      [styles.secondary]: variant === 'secondary',
      [styles.primary]: variant === 'primary',
    },
    className,
  )

  return (
    <button className={buttonClassName} name={name} onClick={onClick}>
      {icon && <Icon name={icon} />}
      <span>{title}</span>
    </button>
  )
}

export default memo(Button)
